import { combineReducers } from 'redux-starter-kit';
import metroReducer from '../features/metroList/metroSlice';
import allNeighbourhoodsReducer from '../features/allNeighbourhoods/allNeighbourhoodsSlice';
import neighbourhoodReducer from '../features/neighbourhoodFilter/neighbourhoodSlice';
import settingReducer from '../features/settingFilter/settingSlice';
import venueReducer from '../features/venueList/venueSlice';
import personalRatingReducer from '../features/personalRating/personalRatingSlice';
import personalRatingTypeReducer from '../features/personalRatingType/personalRatingTypeSlice';
import loaderReducer from '../features/loadingSpinner/loaderSlice';
import appReducer from '../app/appSlice';

const rootReducer = combineReducers({
  metros: metroReducer,
  allNeighbourhoods: allNeighbourhoodsReducer,
  neighbourhoods: neighbourhoodReducer,
  settings: settingReducer,
  venues: venueReducer,
  personalRatings: personalRatingReducer,
  personalRatingTypes: personalRatingTypeReducer,
  loader: loaderReducer,
  app: appReducer,
});

export default rootReducer;
