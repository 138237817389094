import App from 'next/app';
import React from 'react';
import Head from 'next/head';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { getAndSetMetros } from '../features/metroList/metroSlice';
import { getAndSetAllNeighbourhoods } from '../features/allNeighbourhoods/allNeighbourhoodsSlice';
import { setInitialRender } from '../app/appSlice';
import { configureStore } from 'redux-starter-kit';
import withRedux from "next-redux-wrapper";
import rootReducer from '../app/rootReducer';

Sentry.init({
  dsn: 'https://c6fd55b0a5144ebeaeaf84e44215e5fa@sentry.io/1829992'
});

const makeStore = (initialState, options) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
  });
}

class MyApp extends App {
  static async getInitialProps({Component, ctx}) {
    // console.log("Executing _app.js getInitialProps");
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    await ctx.store.dispatch(setInitialRender(true));
    await ctx.store.dispatch(getAndSetMetros());
    await ctx.store.dispatch(getAndSetAllNeighbourhoods());
    return {
      pageProps: {...pageProps},
    }
  };

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
    });
    super.componentDidCatch(error, errorInfo);
  };

  render = props => {
    // console.log("Executing _app.js render function");
    let { Component, pageProps, store } = this.props;
    if (process.browser) {
      window.addEventListener('error', function(e) {
        const isImg = e.target.tagName === 'IMG';
        const src = e.target.src;
        if (isImg) {
          Sentry.captureMessage(`Image 404 Error loading ${src}`);
        };
      }, true);
    };
    return (
    <Provider store={store}>
      <Head>
        <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
      </Head>
      <Component {...pageProps} />
    </Provider>
    )
  };
};

export default withRedux(makeStore)(MyApp);
